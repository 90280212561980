@use '../lib/screen';
@use '../lib/typography';

.cart {
  padding-bottom: 96px;

  @include screen.from(screen.$md) {
    padding-top: 16px;
  }
}

.cart__empty {
  display: flex;
  margin-top: 24px;

  @include screen.from(screen.$sm) {
    margin-top: 32px;
  }
}

.cart__empty-icon {
  flex-shrink: 0;
  margin-right: 8px;
}

.cart__empty-title {
  margin-bottom: 4px;
  font-weight: 550;
  font-size: 16px;
  line-height: 24px;
}

.cart__sections {
  @include screen.from(screen.$md) {
    display: grid;
    grid-template:
      'quick      products'
      'checkout   products' 1fr
      / minmax(0, 1fr) minmax(0, 1fr);
    gap: 24px var(--size-gap);
    width: var(--size-container-width);
    margin: 0 auto;
    padding: 0 var(--size-container-sidegap);
  }
}

.cart__section {
  @include screen.to(screen.$md) {
    overflow: hidden;

    &:not(:last-of-type) {
      border-bottom: 8px solid var(--color-grey-05);
    }
  }
}

.cart__section--products {
  padding: 16px var(--size-container-sidegap);

  @include screen.from(screen.$md) {
    grid-area: products;
    padding: 0 0 0 16px;
  }

  @include screen.from(screen.$lg) {
    padding-left: 24px;
  }
}

.cart__section--quick {
    padding: 16px var(--size-container-sidegap);

  @include screen.from(screen.$md) {
    grid-area: quick;
    padding: 0 0 24px;
    border-bottom: 1px solid var(--color-lines);
  }
}

.cart__section--checkout {
  @include screen.from(screen.$md) {
    grid-area: checkout;
  }
}

.cart__products {
  @include screen.from(screen.$md) {
    position: sticky;
    top: 24px;

    .global-is-header-shown & {
      top: calc(var(--size-header-panel-height) + 16px);
      transition: top 0.5s var(--ease-in-out-quart);
    }
  }
}

.cart__quick-button {
  @include screen.to(screen.$sm) {
    width: 100%;
  }
}

.cart__heading-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cart__heading {
  display: inline-block;
}

.cart__count {
  display: inline-block;
  vertical-align: 10%;
  color: var(--color-text-aux);
  font-size: 12px;
  line-height: 16px;
}

.cart__product {
  display: flex;

  &:not(:last-child) {
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--color-lines);
  }
}

.cart__product-image-link {
  display: block;
  flex-shrink: 0;
  overflow: hidden;
  width: 88px;
  height: 88px;
  margin-right: 12px;
  border-radius: 12px;
  background: var(--color-blocks) url(../svg/product-bg-sm.svg) no-repeat center;
}

.cart__product-image {
  display: block;
  width: 100%;
  height: 100%;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.cart__product-info {
  flex-grow: 1;
}

.cart__product-title {
  margin-bottom: 4px;
  font-weight: 350;
  font-size: 12px;
  line-height: 16px;

  .cart__product:has(.cart__product-image-link:hover) & {
    color: var(--color-accent);
  }

  @include typography.line-clamp(2);
}

.cart__product-prices {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.cart__product-price {
  font-weight: 550;
  font-size: 14px;
  line-height: 20px;

  &.is-discount {
    color: var(--color-marketing);
  }

  @include screen.from(screen.$sm) {
    font-size: 14px;
  }
}

.cart__product-old-price {
  margin-left: 4px;
  padding-top: 2px;
  color: var(--color-grey-3);
  font-weight: 450;
  font-size: 11px;
  line-height: 16px;
  text-decoration: line-through;
}

.cart__product-bar {
  display: flex;
  align-items: center;
}

.cart__product-favorite {
  margin-left: auto;
}

.cart__checkout-section {
  padding: 16px var(--size-container-sidegap);

  &:not(:last-of-type) {
    border-bottom: 8px solid var(--color-grey-05);
  }

  @include screen.from(screen.$md) {
    padding: 0 0 24px;

    &:not(:last-of-type) {
      margin-bottom: 24px;
      border-bottom: 1px solid var(--color-lines);
    }
  }
}

.cart__controls-group {
  overflow: hidden;
  border: 1px solid var(--color-lines);
  border-radius: 8px;
}

.cart__controls-group-item {
  padding: 16px;

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-lines);
  }
}

.cart__suite-inputs {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 8px;
}

.cart__details {
  display: block;
}

.cart__details--is-total {
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid var(--color-lines);
}

.cart__details-row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  &:not(:last-of-type) {
    margin-bottom: 12px;
  }
}

.cart__details-name {
  width: 70%;
}

.cart__details-value {
  width: 30%;
  text-align: right;
}

.cart__details-value--is-discount {
  color: var(--color-marketing);
}

.cart__details-name--total,
.cart__details-value--total {
  font-weight: 550;
  font-size: 16px;
  line-height: 24px;
}

.cart__details-name--is-old-price,
.cart__details-value--is-old-price {
  color: var(--color-grey-3);
  font-size: 14px;
  line-height: 20px;
}

.cart__legal {
  color: var(--color-text-aux);
  font-size: 12px;
  text-align: center;
}

.cart__map {
  overflow: hidden;
  min-height: 340px;
  border: 1px solid var(--color-lines);
  border-radius: 8px;
  background: var(--color-blocks);
  animation: loading-block 0.75s ease infinite alternate both;
}

.cart__postcard {
  display: block;
  overflow: hidden;
  width: 100%;
  max-width: 336px;
  margin: 0 auto;
  border-radius: 8px;
}

.cart__quick {
  padding: 16px;
  border-radius: 8px;
  background: var(--color-blocks);
}

.cart__quick-title {
  margin-bottom: 4px;
  font-weight: 550;
}

.cart__quick-text {
  margin-bottom: 16px;
}

.cart__promocode {
  position: relative;
}

.cart__promocode-input {
  flex: 1;

  input {
    text-transform: uppercase;
  }

  &.has-valid-promo-code {
    input {
      padding-left: 36px;
    }
  }
}

.cart__promocode-icon {
  position: absolute;
  top: 24px;
  left: 16px;
  color: var(--color-success);
}

.cart__promocode-button {
  position: absolute;
  top: 8px;
  right: 8px;
}
