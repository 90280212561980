@use '../lib/screen';

.product-gallery {
  @include screen.from(screen.$md) {
    display: grid;
    grid-template-columns: 64px minmax(0, 1fr);
    gap: 16px;
    align-items: flex-start;
  }

  @include screen.from(screen.$lg) {
    grid-template-rows: 567px;
  }
}

.product-gallery__thumbnails {
  display: none;

  @include screen.from(screen.$md) {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    max-height: 468px;

    .swiper {
      height: 100%;
    }
  }
}

.product-gallery__thumbnail {
  position: relative;
  overflow: hidden;
  width: 64px;
  height: 64px;
  padding: 4px;
  border: 1px solid var(--color-grey-1);
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  transition: border-color var(--transition-in);
  appearance: none;

  &:hover,
  &:focus-visible {
    border-color: var(--color-grey-2);
    outline: none;
  }

  &.swiper-slide-thumb-active {
    border-color: var(--color-accent);
  }
}

.product-gallery__thumbnail-img {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background-color: var(--color-blocks);

  &.is-video {
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: 24px;
      height: 24px;
      background: url(../svg/video-play.svg) no-repeat center;
      background-size: contain;
      transform: translate(-50%, -50%);
    }
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.product-gallery__thumbnails-prev,
.product-gallery__thumbnails-next {
  display: flex;
  align-content: center;
  justify-content: center;
  width: 64px;
  height: 28px;
  cursor: pointer;
  user-select: none;
  transition: color var(--transition-in);
  appearance: none;

  &:hover,
  &:focus-visible {
    color: var(--color-accent);
  }

  &.swiper-button-disabled {
    color: var(--color-grey-2);
  }
}

.product-gallery__thumbnails-prev {
  margin-bottom: 4px;
}

.product-gallery__thumbnails-next {
  margin-top: 4px;
}

.product-gallery__prev,
.product-gallery__next {
  display: none;

  @include screen.from(screen.$md) {
    position: absolute;
    top: 50%;
    z-index: 1;
    display: flex;
    align-content: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    color: #fff;
    opacity: 0;
    visibility: visible;
    cursor: pointer;
    user-select: none;
    transition: opacity var(--transition-in), visibility var(--transition-in);
    transform: translateY(-50%);
    appearance: none;

    .i {
      width: 32px;
      height: 32px;
    }

    .product-gallery__slider:hover & {
      opacity: 1;

      &.swiper-button-disabled {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
}

.product-gallery__prev {
  left: 8px;
}

.product-gallery__next {
  right: 8px;
}

.product-gallery__slider {
  --swiper-theme-color: var(--color-accent);
  --swiper-pagination-bullet-inactive-color: var(--color-grey-2);
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-width: 8px;
  --swiper-pagination-bullet-height: 8px;
  --swiper-pagination-bullet-horizontal-gap: 3px;

  .swiper {
    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;
    margin-bottom: 14px;
    padding-bottom: 16px;
  }

  .swiper-pagination {
    bottom: 0;
    display: flex;
    justify-content: center;
    line-height: 8px;
  }

  @include screen.from(screen.$sm) {
    .swiper {
      overflow: hidden;
      border-radius: 8px;
    }
  }

  @include screen.from(screen.$md) {
    .swiper {
      padding-bottom: 0;
    }

    .swiper-pagination {
      display: none;
    }
  }
}

.product-gallery__img {
  position: relative;
  display: block;
  overflow: hidden;
  max-width: 576px;
  max-height: 576px;
  margin: 0 auto;
  background: url(../svg/product-bg.svg) no-repeat center;

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &.is-small img {
    object-fit: scale-down;
  }

  @include screen.from(screen.$sm) {
    border-radius: 8px;
  }
}
