@use '../lib/screen';

.filter {
  @include screen.to(screen.$md) {
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--z-index-dialog);
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: var(--color-bg);

    &.is-transition-enter {
      transition: transform 0.2s var(--ease-in-out-quart);
    }

    &.is-transition-enter-start {
      transform: translateX(-100%);
    }

    &.is-transition-enter-end {
      transform: none;
    }

    &.is-transition-leave {
      transition: transform 0.1s var(--ease-in-out-quart);
    }

    &.is-transition-leave-start {
      transform: none;
    }

    &.is-transition-leave-end {
      transform: translateX(-100%);
    }
  }

  @include screen.from(screen.$md) {
    display: block !important;
  }
}

.filter__mobile-topbar {
  position: relative;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: var(--mobile-menu-topbar-height);
  padding: 0 var(--size-container-sidegap);
  border-bottom: 1px solid var(--color-lines);

  @include screen.from(screen.$md) {
    display: none;
  }
}

.filter__mobile-heading {
  font-weight: 450;
  text-align: center;
}

.filter__mobile-clear {
  position: absolute;
  top: 50%;
  left: 8px;
  min-height: 44px;
  padding: 0 8px;
  color: var(--color-accent);
  cursor: pointer;
  user-select: none;
  transition: color var(--transition-in);
  transform: translateY(-50%);
  appearance: none;

  &:hover,
  &:focus-visible {
    color: var(--color-accent-active);
  }
}

.filter__mobile-back {
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);

  @include screen.from(screen.$sm) {
    left: 16px;
  }
}

.filter__mobile-close {
  position: absolute;
  top: 8px;
  right: 8px;

  @include screen.from(screen.$sm) {
    top: 12px;
    right: 16px;
  }
}

.filter__content {
  @include screen.to(screen.$md) {
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;
    // width: var(--size-container-width);
    // margin: 0 auto;
    padding: 0 var(--size-container-sidegap);
  }
}

.filter__content-inner {
  @include screen.to(screen.$md) {
    padding: 16px 0;
  }
}

.filter__footer {
  padding: 12px var(--size-container-sidegap);
  border-top: 1px solid var(--color-lines);
  background-color: var(--color-bg);

  @include screen.from(screen.$md) {
    display: none;
  }
}

.filter__applied-filters {
  margin-bottom: 8px;

  @include screen.from(screen.$md) {
    display: none;
  }
}

.filter__section {
  border-bottom: 1px solid var(--color-lines);
}

.filter__section-title {
  position: relative;
  display: flex;
  gap: 4px;
  align-items: center;
  width: 100%;
  padding: 16px 24px 16px 0;
  border: 0;
  border-radius: 0;
  text-align: left;
  cursor: pointer;
  user-select: none;
  appearance: none;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    display: block;
    width: 20px;
    height: 20px;
    background-color: var(--color-grey-3);
    transition: transform var(--transition-in);
    transform: translateY(-50%);
    mask-image: url(../svg/icons/chevron-right.svg);
  }

  @include screen.from(screen.$md) {
    padding: 12px 24px 12px 0;
    font-weight: 550;

    &::after {
      mask-image: url(../svg/icons/chevron-down.svg);
    }

    &[aria-expanded='true'] {
      &::after {
        transform: translateY(-50%) scaleY(-1);
      }
    }
  }
}

.filter__section-title-inner {
  @include screen.to(screen.$md) {
    display: flex;
    align-items: center;

    &::after {
      content: '';
      width: 10px;
      height: 10px;
      margin-left: 8px;
      border-radius: 100%;
      background-color: var(--color-accent);
    }
  }
}

.filter__section-body {
  @include screen.to(screen.$md) {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: var(--color-bg);

    &.is-transition-enter {
      transition: transform 0.2s var(--ease-in-out-quart);
    }

    &.is-transition-enter-start {
      transform: translateX(100%);
    }

    &.is-transition-enter-end {
      transform: none;
    }

    &.is-transition-leave {
      transition: transform 0.1s var(--ease-in-out-quart);
    }

    &.is-transition-leave-start {
      transform: none;
    }

    &.is-transition-leave-end {
      transform: translateX(100%);
    }
  }

  @include screen.from(screen.$md) {
    display: block;
    padding-bottom: 16px;
  }
}

.filter__category-item {
  &:not(:last-child) {
    margin-bottom: 4px;
  }
}

.filter__categories {
  @include screen.from(screen.$md) {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 288px;
  }
}

.filter__category-list {
  .filter__category-group-button + & {
    margin-top: 4px;
  }
}

.filter__category-group-button {
  position: relative;
  width: 100%;
  padding: 16px 28px 16px calc(8px * var(--depth, 0));
  border-bottom: 1px solid var(--color-lines);
  border-radius: 0;
  text-align: left;
  cursor: pointer;
  user-select: none;
  appearance: none;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    display: block;
    width: 20px;
    height: 20px;
    background-color: var(--color-grey-4);
    transition: transform var(--transition-in);
    transform: translateY(-50%);
    mask-image: url(../svg/icons/chevron-down.svg);
  }

  &[aria-expanded='true'] {
    &::after {
      transform: translateY(-50%) scaleY(-1);
    }
  }

  @include screen.from(screen.$md) {
    padding: 4px 8px;
    border: 0;

    &::after {
      right: 8px;
    }
  }
}

.filter__category-link {
  position: relative;
  display: block;

  @include screen.to(screen.$md) {
    padding: 16px 28px 16px calc(8px * var(--depth, 0));

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      display: block;
      width: 20px;
      height: 20px;
      border: 1px solid var(--color-lines);
      border-radius: 100%;
      transition: border-color var(--transition-in), background-color var(--transition-in);
      transform: translateY(-50%);
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 5px;
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 100%;
      background-color: #fff;
      transform: translateY(-50%);
    }

    &:focus-visible,
    &:hover {
      outline: none;

      &::before {
        border-color: var(--color-accent);
      }
    }

    &[aria-current='page'] {
      &::before {
        border-color: var(--color-accent);
        background-color: var(--color-accent);
      }
    }

    .filter__category-item:not(:last-child) & {
      border-bottom: 1px solid var(--color-lines);
    }
  }

  @include screen.from(screen.$md) {
    padding: 4px 8px;

    &[aria-current='page'] {
      border-radius: 4px;
      background-color: var(--color-grey-05);
    }

    &.is-grouped-link {
      padding-left: 16px;
    }

    &.is-back {
      position: relative;
      padding-left: 24px;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 20px;
        height: 20px;
        background-color: var(--color-icons);
        transform: translateY(-50%);
        mask-image: url(../svg/icons/chevron-left.svg);
      }
    }
  }
}

.filter__checkboxes {
  @include screen.from(screen.$md) {
    & > *:not(:last-child) {
      margin-bottom: 4px;
    }
  }
}

.filter__checkbox {
  display: block;
  cursor: pointer;

  @include screen.to(screen.$md) {
    padding: 16px 0;

    &:not(:last-child) {
      border-bottom: 1px solid var(--color-lines);
    }
  }

  @include screen.from(screen.$md) {
    padding: 4px 0;
  }
}

.filter__checkbox-input {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  white-space: nowrap;
  clip-path: inset(50%);
}

.filter__checkbox-label {
  position: relative;
  display: flex;
  align-items: center;
  padding-right: 28px;
  line-height: 20px;
  user-select: none;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 20px;
    height: 20px;
    border: 1px solid var(--color-lines);
    border-radius: 4px;
    background-color: #fff;
    transition: background-color var(--transition-in), border-color var(--transition-in),
      box-shadow var(--transition-in);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 20px;
    height: 20px;
    background: url(../svg/checkbox-mark.svg) no-repeat center;
  }

  &:hover,
  &:focus {
    &::before {
      border-color: var(--color-accent);
    }
  }

  .filter__checkbox-input:focus-visible ~ & {
    &::before {
      border-color: var(--color-accent);
      box-shadow: var(--focus-visible-shadow);
    }
  }

  .filter__checkbox-input:checked ~ & {
    &::before {
      border-color: var(--color-accent);
      background-color: var(--color-accent);
    }
  }

  @include screen.from(screen.$md) {
    padding-left: 28px;

    &::before {
      right: auto;
      left: 0;
    }

    &::after {
      right: auto;
      left: 0;
    }
  }
}

.filter__checkbox-count {
  display: inline-block;
  margin-left: 4px;
  color: var(--color-text-aux);
}

.filter__radios {
  @include screen.from(screen.$md) {
    & > *:not(:last-child) {
      margin-bottom: 4px;
    }
  }
}

.filter__radio {
  display: block;
  cursor: pointer;

  @include screen.to(screen.$md) {
    padding: 16px 0;

    &:not(:last-child) {
      border-bottom: 1px solid var(--color-lines);
    }
  }

  @include screen.from(screen.$md) {
    padding: 4px 0;
  }
}

.filter__radio-input {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  white-space: nowrap;
  clip-path: inset(50%);
}

.filter__radio-label {
  position: relative;
  display: flex;
  align-items: center;
  padding-right: 28px;
  line-height: 20px;
  user-select: none;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 20px;
    height: 20px;
    border: 1px solid var(--color-lines);
    border-radius: 999px;
    background-color: #fff;
    transition: background-color var(--transition-in), border-color var(--transition-in),
      box-shadow var(--transition-in);
  }

  &::after {
    content: '';
    position: absolute;
    top: 5px;
    right: 5px;
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 999px;
    background: #fff;
  }

  &:hover,
  &:focus {
    &::before {
      border-color: var(--color-accent);
    }
  }

  .filter__radio-input:focus-visible ~ & {
    &::before {
      border-color: var(--color-accent);
      box-shadow: var(--focus-visible-shadow);
    }
  }

  .filter__radio-input:checked ~ & {
    &::before {
      border-color: var(--color-accent);
      background-color: var(--color-accent);
    }
  }

  @include screen.from(screen.$md) {
    padding-left: 28px;

    &::before {
      right: auto;
      left: 0;
    }

    &::after {
      right: auto;
      left: 5px;
    }
  }
}

.filter__toggle {
  margin: 12px 0;
}

.filter__prices {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 8px;
  margin-bottom: 8px;
}

.filter__price {
  position: relative;
}

.filter__price-label {
  position: absolute;
  top: 50%;
  left: 12px;
  z-index: 1;
  color: var(--color-text-aux);
  text-transform: lowercase;
  pointer-events: none;
  user-select: none;
  transform: translateY(-50%);
}

.filter__price-input {
  width: 100%;
  min-height: 10px;
  padding: 11px 0 11px 32px;
  border: 1px solid var(--color-lines);
  border-radius: 8px;
  font-size: 16px; // prevent ios zoom on focus
  line-height: 1.4;
  resize: none;
  transition: border-color var(--transition-in), box-shadow var(--transition-in);
  appearance: none;

  &:hover {
    border-color: var(--color-accent);
  }

  &:focus {
    border-color: var(--color-accent);
    box-shadow: 0 0 0 3px rgb(193 35 37 / 16%);
  }

  &:focus-visible {
    outline: 0;
  }

  &::-ms-clear {
    display: none;
  }

  &.is-not-empty {
    padding-right: 32px;
  }

  @include screen.from(screen.$md) {
    font-size: 14px;
  }
}

.filter__price-clear {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
}

.filter__colors {
  @include screen.from(screen.$md) {
    display: flex;
    flex-wrap: wrap;
  }
}

.filter__color {
  position: relative;
  display: block;
  cursor: pointer;

  @include screen.to(screen.$md) {
    padding: 16px 36px 16px 28px;

    &:not(:last-child) {
      border-bottom: 1px solid var(--color-lines);
    }
  }

  @include screen.from(screen.$md) {
    margin-right: 4px;
    margin-bottom: 4px;
  }
}

.filter__color-input {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  white-space: nowrap;
  clip-path: inset(50%);
}

.filter__color-label {
  @include screen.from(screen.$md) {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    white-space: nowrap;
    clip-path: inset(50%);
  }
}

.filter__color-count {
  display: inline-block;
  margin-left: 4px;
  color: var(--color-text-aux);
}

.filter__color-checkbox {
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
  width: 20px;
  height: 20px;
  border: 1px solid var(--color-lines);
  border-radius: 4px;
  background-color: #fff;
  transition: background-color var(--transition-in), border-color var(--transition-in),
    box-shadow var(--transition-in);
  transform: translateY(-50%);

  &::after {
    content: '';
    position: absolute;
    top: -1px;
    right: -1px;
    display: block;
    width: 20px;
    height: 20px;
    background: url(../svg/checkbox-mark.svg) no-repeat center;
  }

  .filter__color:hover & {
    border-color: var(--color-accent);
  }

  .filter__color-input:focus-visible ~ & {
    border-color: var(--color-accent);
    box-shadow: var(--focus-visible-shadow);
  }

  .filter__color-input:checked ~ & {
    border-color: var(--color-accent);
    background-color: var(--color-accent);
  }

  @include screen.from(screen.$md) {
    display: none;
  }
}

.filter__color-color {
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  overflow: hidden;
  width: 20px;
  height: 20px;
  border: 1px solid var(--color-lines);
  border-radius: 100%;
  transition: border-color var(--transition-in);
  transform: translateY(-50%);

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background-color: var(--color-grey-05);
    transform: translate(-50%, -50%);
  }

  &.is-red::before {
    background-color: #e50000;
  }

  &.is-white::before {
    background-color: #fff;
  }

  &.is-brown::before {
    background-color: #964b00;
  }

  &.is-black::before {
    background-color: #000;
  }

  &.is-pink::before {
    background-color: #ffc0cb;
  }

  &.is-purple::before {
    background-color: #8b00ff;
  }

  &.is-blue::before {
    background-color: #00f;
  }

  &.is-beige::before {
    background-color: #f5f5dc;
  }

  &.is-orange::before {
    background-color: #ffa500;
  }

  &.is-yellow::before {
    background-color: #ff0;
  }

  &.is-green::before {
    background-color: #008000;
  }

  &.is-very-pery::before {
    background-color: #6467ac;
  }

  &.is-bordeaux::before {
    background-color: #b00000;
  }

  &.is-peach::before {
    background-color: peachpuff;
  }

  &.is-coral::before {
    background-color: #f36f63;
  }

  &.is-classic-blue::before {
    background-color: #35558a;
  }

  &.is-mto::before {
    background: linear-gradient(
      314.99deg,
      #f00 5.21%,
      #ffe600 27.74%,
      #00a3ff 49.37%,
      #ad00ff 72.26%,
      #f00 94.61%
    );
  }

  &.is-grey-yellow::before {
    background: linear-gradient(314.99deg, #939597 0%, #939597 50%, #f8e00e 50%, #f8e00e 100%);
  }

  &.is-multi::before {
    background: #0708fa url(../images/multicolor.png) no-repeat center;
  }

  &.is-rainbow::before {
    background: linear-gradient(
      314.99deg,
      #f00 5.21%,
      #ffe600 27.74%,
      #00a3ff 49.37%,
      #ad00ff 72.26%,
      #f00 94.61%
    );
  }

  @include screen.from(screen.$md) {
    position: relative;
    top: auto;
    left: auto;
    width: 36px;
    height: 36px;
    transform: none;

    &::before {
      width: 28px;
      height: 28px;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background: url(../svg/filter-color-check.svg) no-repeat center;
      opacity: 0;
      transition: opacity var(--transition-in);
    }

    .filter__color:hover & {
      border-color: var(--color-accent);
    }

    .filter__color-input:focus-visible ~ & {
      border-color: var(--color-accent);
      box-shadow: var(--focus-visible-shadow);
    }

    .filter__color-input:checked ~ & {
      border-color: var(--color-accent);

      &::after {
        opacity: 1;
      }
    }
  }
}
