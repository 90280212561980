@use '../lib/screen';

.select {
  position: relative;
  display: block;
}

.select__label {
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 1;
  color: var(--color-text-aux);
  pointer-events: none;
  user-select: none;
  transition: transform 0.2s var(--ease-in-out-quart);
  transform: none;
  transform-origin: left center;

  .select.is-focused & {
    transform: translate(1px, -11px) scale(0.8);
  }
}

.select__wrapper {
  position: relative;
  display: flex;
  min-height: 52px;

  &.disabled {
    opacity: 0.7;
  }
}

select.select__select {
  // original select input should have the same style is TOM's control, to avoid FOUC
  position: relative;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  width: 100%;
  min-height: 52px;
  padding: 23px 23px 7px 16px;
  border: 1px solid var(--color-lines);
  border-radius: 8px;
  background: transparent url(../svg/icons/chevron-down.svg) no-repeat right 16px center;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  appearance: none;
}

.select__control {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  width: 100%;
  min-height: 52px;
  padding: 23px 23px 7px 16px;
  border: 1px solid var(--color-lines);
  border-radius: 8px;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  transition: border-color var(--transition-in), box-shadow var(--transition-in);

  .select__wrapper:not(.disabled):hover & {
    border-color: var(--color-accent);
  }

  .select__wrapper.focus & {
    border-color: var(--color-accent);
    box-shadow: 0 0 0 3px rgb(193 35 37 / 16%);
  }

  .select__wrapper.single & {
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 16px;
      display: block;
      width: 20px;
      height: 20px;
      background-color: var(--color-grey-3);
      transform: translateY(-50%);
      mask-image: url(../svg/icons/chevron-down.svg);
    }
  }

  .select--sm .select__wrapper.single & {
    &::after {
      right: 12px;
      background-color: var(--color-icons);
      transition: transform var(--transition-in);
    }
  }

  .select.has-error & {
    border-color: var(--color-error);

    &:focus {
      box-shadow: var(--shadow-error);
    }
  }

  .select__wrapper.single.dropdown-active & {
    &::after {
      transform: translateY(-50%) scaleY(-1);
    }
  }

  & > * {
    display: inline-block;
    vertical-align: baseline;
  }

  & > input {
    display: inline-block !important;
    flex: 1 1 auto;
    min-width: 7rem;
    max-width: 100% !important;
    min-height: 0 !important;
    max-height: none !important;
    margin: 0 !important;
    padding: 0 !important;
    border: 0 none !important;
    background: none !important;
    box-shadow: none !important;
    font-size: 14px;
    line-height: 20px;
    text-indent: 0 !important;
    user-select: auto !important;

    &:focus {
      outline: none !important;
    }

    &::-ms-clear {
      display: none;
    }

    .select__wrapper.has-items & {
      margin: 0 4px !important;
    }

    .select__wrapper:not(.has-items) & {
      position: absolute;
      left: -10000px;
      opacity: 0;
    }

    .select__wrapper.input-hidden & {
      position: absolute;
      left: -10000px;
      opacity: 0;
    }
  }
}

.select--sm {
  .select__wrapper {
    min-height: 36px;
  }

  .select__label {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    white-space: nowrap;
    clip-path: inset(50%);
  }

  .select__control,
  select.select__select {
    min-height: 36px;
    padding: 7px 40px 7px 11px;
    background-position: right 12px center;
  }
}

.select__item {
  display: flex;
  align-items: center;
}

.select__dropdown {
  &[data-selectable] {
    overflow: hidden;
    cursor: pointer;
  }

  @include screen.to(screen.$md) {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: var(--z-index-dialog);
    width: 100%;

    &::before {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      background-color: var(--color-overlay);
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      border-radius: 16px 16px 0 0;
      background: var(--color-bg);
      box-shadow: 0 0 20px rgb(0 0 0 / 12%), 0 0 0 1px rgb(0 0 0 / 8%);
    }
  }

  @include screen.from(screen.$md) {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 10;
    box-sizing: border-box;
    min-width: 100%;
    border-top: 0 none;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 0 12px rgb(0 0 0 / 12%), 0 0 0 1px rgb(0 0 0 / 8%);
  }
}

.select__dropdown-heading {
  content: var(--select-label, '');
  position: relative;
  display: block;
  padding: 16px;
  font-weight: 450;
  font-size: 16px;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 16px;
    display: block;
    width: 20px;
    height: 20px;
    background-color: var(--color-icons);
    transform: translateY(-50%);
    mask-image: url(../svg/icons/close.svg);
  }

  @include screen.from(screen.$md) {
    display: none;
  }
}

.select__dropdown-content {
  overflow-x: hidden;
  overflow-y: auto;
  overflow-scrolling: touch;
  max-height: 216px;
  padding: 8px 0;
  scroll-behavior: smooth;
}

.select__option {
  position: relative;
  padding: 12px 44px 12px 16px;
  white-space: nowrap;
  cursor: pointer;
  transition: color var(--transition-in);

  &:hover,
  &:focus-visible {
    color: var(--color-accent);
  }

  &.selected {
    color: var(--color-accent);

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 16px;
      display: block;
      width: 20px;
      height: 20px;
      background-color: var(--color-accent);
      transform: translateY(-50%);
      mask-image: url(../svg/icons/checkmark.svg);
    }
  }

  @include screen.from(screen.$md) {
    padding: 8px 40px 8px 12px;

    &.selected {
      &::after {
        right: 8px;
      }
    }
  }
}
