@use "../lib/screen";

.stepper {
  display: flex;
  overflow: hidden;
  min-width: 110px;
  border: 1px solid var(--color-grey-1);
  border-radius: 8px;
  transition: border-color var(--transition-in);

  &:hover,
  &:focus-within {
    border-color: var(--color-accent);
  }

  &:focus-within {
    box-shadow: var(--focus-visible-shadow);
  }
}

.stepper__minus,
.stepper__plus {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 34px;
  background-color: #fff;
  outline: none;
  cursor: pointer;
  user-select: none;
  appearance: none;

  &::before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background-color: var(--color-icons);
    transition: background-color var(--transition-in);
    mask-repeat: no-repeat;
    mask-size: 100% 100%;
  }

  &:hover,
  &:focus-visible {
    &::before {
      background-color: var(--color-accent);
    }
  }

  &[disabled] {
    cursor: not-allowed;

    &::before {
      background-color: var(--color-grey-2);
    }
  }
}

.stepper__minus::before {
  mask-image: url(../svg/icons/minus.svg);
}

.stepper__minus.is-delete::before {
  mask-image: url(../svg/icons/trash-20.svg);
}

.stepper__plus::before {
  mask-image: url(../svg/icons/plus.svg);
}

.stepper__input {
  display: block;
  flex: 1;
  width: 0;
  outline: none;
  font-weight: 450;
  font-size: 14px;
  text-align: center;
  appearance: none;
}

@include screen.from(screen.$md) {
  .md\:stepper--m {
    min-width: 132px;

    .stepper__minus,
    .stepper__plus {
      width: 43px;
      height: 42px;
    }
  }
}
