@use '../lib/screen';

:root {
  --font-primary: 'Roboto Flex', sans-serif;
  --font-logo: 'IBM Plex Serif', serif;
  --color-accent-light: #e6a7a8;
  --color-accent: #c12325;
  --color-accent-active: #a21d1f;
  --color-grey-05: #f6f4f4;
  --color-grey-1: #ebe6e6;
  --color-grey-2: #c4c4c4;
  --color-grey-3: #808080;
  --color-grey-4: #333;
  --color-white: #fff;
  --color-white-4: rgb(255 255 255 / 4%);
  --color-white-16: rgb(255 255 255 / 16%);
  --color-white-50: rgb(255 255 255 / 50%);
  --color-black: #000;
  --color-yellow: #ffa600;
  --color-bg: #fff;
  --color-error: #f3511b;
  --color-marketing: #f3511b;
  --color-marketing-warranty: #1b7ff3;
  --color-success: #298e37;
  --color-success-active: #22762d;
  --color-overlay: rgb(0 0 0 / 50%);
  --color-blocks: var(--color-grey-05);
  --color-blocks-loading: #fcfbfb;
  --color-lines: var(--color-grey-1);
  --color-text-main: var(--color-grey-4);
  --color-text-aux: var(--color-grey-3);
  --color-icons: var(--color-grey-4);
  --color-outline: rgb(193 35 37 / 16%);
  --ease-in-out-quart: cubic-bezier(0.76, 0, 0.24, 1);
  --transition-in: 0.2s ease-out;
  --transition-out: 0.15s ease-in;
  --transition-sidebar: transform 0.2s var(--ease-in-out-quart),
    visibility 0.2s var(--ease-in-out-quart);
  --focus-visible-shadow: 0 0 0 3px rgb(193 35 37 / 16%);
  --shadow-error: 0 0 0 3px rgb(243 81 27 / 16%);
  --shadow-success: 0 0 0 3px rgb(113 243 27 / 16%);
  --shadow-dropdown: 0 0 12px rgb(0 0 0 / 12%), 0 0 0 1px rgb(0 0 0 / 8%);
  --size-container-width: 100%;
  --size-container-sidegap: 16px;
  --size-container-offset-left: calc(
    (100vw - var(--size-container-width)) / 2 + var(--size-container-sidegap)
  );
  --size-gap: 8px;
  --size-header-panel-height: 117px;
  --size-tabbar-height: 64px;
  --size-buy-bar-height: 61px;
  --mobile-menu-topbar-height: 56px;
  --z-index-tabbar: 10000;
  --z-index-header: 10170;
  --z-index-fab: 10150;
  --z-index-b24-widget: 10150;
  --z-index-b24-widget-overlay: 10180;
  --z-index-b24-widget-on-top: 10181;
  --z-index-notifications: 10200;
  --z-index-tabbar-on-top: 12000;
  --z-index-dialog: 13000;
  --z-index-dropdown: 13000;
}

@include screen.from(screen.$sm) {
  :root {
    --size-container-sidegap: 24px;
    --size-gap: 12px;
    --mobile-menu-topbar-height: 64px;
  }
}

@include screen.from(screen.$md) {
  :root {
    --size-container-sidegap: 32px;
    --size-gap: 16px;
    --size-header-panel-height: 170px;
  }
}

@include screen.from(screen.$lg) {
  :root {
    --size-container-width: 1200px;
    --size-container-sidegap: 40px;
  }
}

html {
  width: 100%;
  height: 100%;
  scroll-padding-top: calc(var(--size-header-panel-height, 200) + 24px);
  scroll-behavior: smooth;
}

.is-no-scroll body {
  overflow: hidden !important;
  height: auto !important;
  -webkit-overflow-scrolling: none;
  touch-action: none;
  overscroll-behavior: none;
}

body {
  min-height: 100%;
  background-color: var(--color-bg);
  color: var(--color-grey-4);
  font-weight: 350;
  font-stretch: 110%;
  font-size: 14px;
  font-family: 'Roboto Flex', sans-serif;
  line-height: 1.5;
  overscroll-behavior: none;

  &::-webkit-scrollbar-thumb {
    border-radius: 999px;
    background-color: var(--color-grey-4);
  }
}

::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 999px;
  background-color: rgb(0 0 0 / 8%);
}

::-webkit-scrollbar-track {
  border-radius: 999px;
  background-color: transparent;
}

::selection {
  background-color: var(--color-accent);
  color: #fff;
}

* {
  // show outline only with keyboard interaction
  outline: none;

  &:focus-visible {
    // replace with shadowbox on rounded objects!
    outline: 3px solid var(--color-outline);
    outline-offset: 2px;
  }
}

img:not([src], [srcset]) {
  visibility: hidden;
}

[x-cloak] {
  display: none !important;
}

.container {
  display: block;
  width: 100%;
  max-width: var(--size-container-width);
  margin: auto;
  padding: 0 var(--size-container-sidegap);
}
